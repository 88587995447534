import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_text_spoiler()
})

function dhsv_vc_text_spoiler() {
  $('.spoiler-item').each(function(index, el) {
    const $el = $(el)

    $('.js-readmore', $el).on('click', function() {
      $(this)
        .toggleClass('active')
        .parent('.spoiler-item')
        .toggleClass('active')
      $('.spoiler-item__content', $el).slideToggle(300)
    })
  })
}
