import { connect } from 'react-redux'
import Component from './component'

const WithData = connect(
  state => ({
    browser: state.browser,
    semesters: state.curriculum.semesters,
    selectedItem: state.curriculum.selectedItem,
    error: state.curriculum.error,
    dataLoaded: state.curriculum.isLoaded,
    strings: state.textstrings.strings,
    stringsLoaded: state.textstrings.isLoaded,
  }),
  dispatch => ({ dispatch })
)(Component)

export default WithData
