import React from 'react'
import PropTypes from 'prop-types'
import LoadDomHandler from './DomHandler'

class EventCard extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { event, activeid } = this.props
    if (event.id == activeid) {
      LoadDomHandler(event.id)
    }
  }

  renderSwitch(param) {
    return (
      <ul className="list-unstyled event-cont">
        {param.length
          ? param.map((item, i) => {
              switch (item.type) {
                case 'person':
                  return (
                    <li key={i}>
                      <p className="mb-0">{item.person}</p>
                    </li>
                  )
                case 'email':
                  return (
                    <li key={i}>
                      <a
                        href={'mailto:' + item.email}
                        className="event-cont__email color-petrol_a"
                      >
                        {item.email}
                      </a>
                    </li>
                  )
                case 'phone':
                  return (
                    <li key={i}>
                      <a
                        href={'tel:' + item.phone.replace(/[^0-9.]/g, '')}
                        className="color-petrol_a"
                      >
                        {item.phone}
                      </a>
                    </li>
                  )
              }
            })
          : ''}
      </ul>
    )
  }

  render() {
    const { event, showMonth, strings, activeid } = this.props
    const {
      id,
      title,
      content,
      e_date,
      iso_date,
      uhrzeit,
      anmelden,
      location,
      kontakt,
    } = event

    const isoDate = event.iso_date
    const date = new Date(isoDate)
    const monthName = date.toLocaleString('de-DE', {
      month: 'long',
    })

    return (
      <React.Fragment>
        {showMonth ? (
          <div className="event__month h2 text-primary2 mb-3 mt-5">
            {monthName}
          </div>
        ) : (
          ''
        )}

        <div
          className={`card event__card px-4 pb-4 pt-4 ${
            activeid == id ? 'event__card--active' : ''
          }`}
        >
          <div className="card-header" id={'heading-' + id}>
            <h2 className="mb-0">
              <button
                className="collapsed event__button d-flex w-100 align-items-center flex-column flex-md-row"
                type="button"
                data-toggle="collapse"
                data-target={'#collapse-' + id}
                aria-expanded={activeid == id ? 'true' : 'false'}
                aria-controls={'collapse-' + id}
              >
                {e_date.length !== 0 ? (
                  <time
                    dateTime={iso_date}
                    className="h3 event__date text-center text-md-left w-100"
                  >
                    {e_date}
                  </time>
                ) : (
                  ''
                )}
                <p className="event__title w-100 text-center text-md-left text-primary2">
                  {title}
                </p>
                {uhrzeit.length !== 0 ? (
                  <time dateTime={uhrzeit} className="event__time h3">
                    {uhrzeit.replace(':', '.')} {strings.uhr}
                  </time>
                ) : (
                  ''
                )}
                <div className="text-center text-md-right mr-md-3 mr-lg-4 mr-0">
                  {anmelden.length !== 0 ? (
                    <a
                      href={anmelden.url}
                      target={anmelden.target}
                      className="event__link w-25 btn btn-beige btn-beige--secondary btn-primary mb-0"
                    >
                      {strings.anmelden}
                    </a>
                  ) : (
                    <div className="event__link btn btn-holder w-25" />
                  )}
                </div>
              </button>
            </h2>
          </div>
          <div
            id={'collapse-' + id}
            className={`collapse ${activeid == id ? 'show' : ''}`}
            aria-labelledby={'heading-' + id}
            data-parent="#eventsAccordion"
          >
            <div
              className="card-body event__body mt-4"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <h4 className="text-primary">{strings.kontakt}</h4>
                {kontakt !== null && kontakt.length !== 0
                  ? this.renderSwitch(kontakt)
                  : ''}
              </div>
              <div className="col-sm-6 col-lg-3">
                <h4 className="text-primary">{strings.ort}</h4>
                {location ? (
                  <a
                    href={
                      'https://www.google.com/maps/place/' +
                      encodeURIComponent(location.address)
                    }
                    target="_blank"
                    className="event__location color-petrol_a"
                  >
                    {location.address}
                  </a>
                ) : (
                  ''
                )}
              </div>
              <div className="col-lg-6 text-md-right">
                <ul className="list-unstyled d-flex mt-3 justify-content-center justify-content-lg-end flex-column flex-sm-row">
                  {anmelden.length !== 0 ? (
                    <li>
                      <a
                        href={anmelden.url}
                        target={anmelden.target}
                        className="btn event__btn"
                      >
                        {strings.anmelden}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {/*<li className="ml-sm-4 mt-3 mt-sm-0">*/}
                  {/*  <a*/}
                  {/*    href="#"*/}
                  {/*    className="btn btn-secondary event__btn event__btn--term"*/}
                  {/*  >*/}
                  {/*    {strings.termin_speichern}*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

EventCard.propTypes = {
  event: PropTypes.object,
  i: PropTypes.number,
  activeid: PropTypes.string,
  showMonth: PropTypes.bool,
  strings: PropTypes.object,
}

export default EventCard
