import React from 'react'
import PropTypes from 'prop-types'
import { loadPosts } from '../../scripts/redux/modules/news'
import { loadStrings } from '../../scripts/redux/modules/textstrings'
import Spinner from '../../scripts/components/Spinner'

class News extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      postsShowed: 9,
      postsLoaded: 18,
      allPostsLoaded: false,
      mediaType: '',
    }

    this.loadMoreNews = this.loadMoreNews.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.resetComponent = this.resetComponent.bind(this)
  }

  loadPosts(offset, per_page) {
    const { cats, posts, all } = this.props
    return this.props.dispatch(
      loadPosts(per_page, offset, cats, posts, all).bind(this)
    )
  }

  loadStrings() {
    return this.props.dispatch(loadStrings().bind(this))
  }

  loadMoreNews() {
    const { postsShowed, postsLoaded, allPostsLoaded } = this.state
    const { browser: { mediaType }, postsPerRow, rows } = this.props
    const perPage = parseInt(rows) * parseInt(postsPerRow[mediaType])
    if (!allPostsLoaded) {
      new Promise(resolve => {
        resolve(this.loadPosts(postsLoaded, perPage))
      }).then(value => {
        if (value.length) {
          this.setState({
            postsLoaded: postsLoaded + value.length,
          })
        } else {
          this.setState({
            allPostsLoaded: true,
          })
        }
      })
    }

    this.setState({
      postsShowed: postsShowed + perPage,
    })
  }

  handleResize() {
    const { browser } = this.props
    const { mediaType } = this.state
    if (mediaType !== browser.mediaType) {
      this.resetComponent()
    }
  }

  resetComponent() {
    const { browser: { mediaType }, postsPerRow, rows } = this.props
    const { postsLoaded } = this.state
    const postsShowed = parseInt(rows) * parseInt(postsPerRow[mediaType])
    const neededPost = postsShowed * 2 - postsLoaded
    if (postsLoaded < postsShowed * 2) {
      this.loadPosts(postsLoaded, neededPost)
      this.setState({
        postsLoaded: postsLoaded + neededPost,
      })
    }

    this.setState({
      postsShowed: postsShowed,
      mediaType: mediaType,
    })
  }

  componentDidMount() {
    const { browser: { mediaType }, postsPerRow, rows } = this.props
    const postsShowed = parseInt(rows) * parseInt(postsPerRow[mediaType])
    const postsLoaded = postsShowed * 2

    this.setState({
      postsLoaded: postsLoaded,
    })

    this.resetComponent()
    this.loadPosts(false, postsLoaded)
    this.loadStrings()

    window.addEventListener('resize', this.handleResize)
  }

  render() {
    const { error, news, dataLoaded, strings, stringsLoaded, all } = this.props
    console.log(all) // eslint-disable-line
    const { postsShowed, postsLoaded } = this.state

    if (error) {
      return <div>Error</div>
    }

    if (!dataLoaded && !stringsLoaded) {
      return <Spinner />
    }

    return (
      <div className="news__container">
        <div className="row news__list">
          {news && news.length
            ? news
                .slice(0, postsShowed)
                .map((item, i) => (
                  <div
                    key={i}
                    className="col-lg-4 col-md-6 col-12"
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))
            : null}
        </div>
        <div className="row">
          <div className="col">
            {news && news.length && postsShowed < postsLoaded ? (
              <button
                className="btn btn-primary"
                onClick={() => this.loadMoreNews()}
              >
                {strings.moreposts ? strings.moreposts : 'Mehr beiträge'}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

News.propTypes = {
  error: PropTypes.bool,
  dataLoaded: PropTypes.bool,
  dispatch: PropTypes.func,
  news: PropTypes.array,
  strings: PropTypes.object,
  stringsLoaded: PropTypes.bool,
  id: PropTypes.string,
  browser: PropTypes.object,
  postsPerRow: PropTypes.object,
  rows: PropTypes.string,
  posts: PropTypes.string,
  cats: PropTypes.string,
  all: PropTypes.bool,
}

export default News
