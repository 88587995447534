/* Don't forget to add string 'news' to function combineReducers in './news.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  posts: [],
}

const LOAD_SEARCH_DATA = 'LOAD_SEARCH_DATA'
const LOAD_SEARCH_DATA_SUCCESS = 'LOAD_SEARCH_DATA_SUCCESS'
const LOAD_SEARCH_DATA_FAILURE = 'LOAD_SEARCH_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SEARCH_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: [...state.posts, ...payload['posts']],
        found_posts: payload['found_posts'],
      }

    case LOAD_SEARCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const loadPosts = (s, posts_per_page, offset) => dispatch => {
  dispatch({ type: LOAD_SEARCH_DATA })

  const params = {}

  s ? (params['s'] = s) : ''
  posts_per_page ? (params['posts_per_page'] = posts_per_page) : ''
  offset ? (params['offset'] = offset) : ''

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  return fetch(`${WP_API_BASE}search${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        type: LOAD_SEARCH_DATA_SUCCESS,
        payload,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_SEARCH_DATA_FAILURE,
      })
    })
}
