import React from 'react'
import PropTypes from 'prop-types'
import JobsList from './jobs-data'

const Jobs = ({ id, category }) => {
  return <JobsList id={id} category={category} />
}

Jobs.propTypes = {
  id: PropTypes.string,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Jobs
