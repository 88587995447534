import { connect } from 'react-redux'
import NewsList from './NewsList'

const WithData = connect(
  state => ({
    browser: state.browser,
    postsPerRow: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3, infinity: 3 },
    news: state.news.posts,
    error: state.news.error,
    dataLoaded: state.news.isLoaded,
    strings: state.textstrings.strings,
    stringsLoaded: state.textstrings.isLoaded,
  }),
  dispatch => ({ dispatch })
)(NewsList)

export default WithData
