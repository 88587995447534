import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_hero_section()
})

function dhsv_vc_hero_section() {
  $('.dhsv_vc_hero_section').each(function(i, el) {
    const $el = $(el)
    const $video = $el.find('video')
    if ($video.length) {
      $video[0].play()
    }
    $(document).on('click', '.ios__close', function() {
      $(this)
        .parent()
        .remove()
    })
    $('.js-play-video', $el).click(function() {
      const videoId = $(this).data('video-id')
      $('.video-' + videoId).remove()
      $(document).on(
        'fullscreenchange mozfullscreenchange MSFullscreenChange webkitfullscreenchange',
        function() {
          $('.fullscreen-opened .video-' + videoId).remove()
          if ($('body').hasClass('fullscreen-opened')) {
            $('body').removeClass('fullscreen-opened')
          }
        }
      )
      var iosClass = iOS() ? 'class="ios__iframe"' : ''
      var iframe = document.createElement('div')
      var css = iOS()
        ? {
            position: 'fixed',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            margin: 'auto',
            width: '100%',
            height: '100%',
            zIndex: '1000',
          }
        : {
            position: 'absolute',
            left: '-100000px',
          }
      var close = iOS() ? '<button class="ios__close">&times;</button>' : ''

      $(iframe)
        .addClass('video-' + videoId)
        .css(css)
      iframe.innerHTML =
        close +
        '<iframe ' +
        iosClass +
        ' width="560" height="315" src="https://www.youtube.com/embed/' +
        videoId +
        '?rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media; fullscreen" allowfullscreen></iframe>'

      $('body').append($(iframe))

      var video = iframe.childNodes[0]

      openFullscreen(video)

      setTimeout(function() {
        $('body').addClass('fullscreen-opened')
      }, 3000)

      // Enter fullscreen mode
      //video.requestFullscreen()
      // const videoId = $(this).data('target')
      // const $video = $('#' + videoId)
      // openFullscreen($video['0']) // we also can use -> document.getElementById(videoId)
    })
  })
}

function iOS() {
  var iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ]

  if (navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true
      }
    }
  }

  return false
}

function openFullscreen(video) {
  if (video.requestFullscreen) {
    video.requestFullscreen()
  } else if (video.mozRequestFullScreen) {
    /* Firefox */
    video.mozRequestFullScreen()
  } else if (video.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    video.webkitRequestFullscreen()
  } else if (video.msRequestFullscreen) {
    /* IE/Edge */
    video.msRequestFullscreen()
  }
}
