import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_video()
})

// play video & hide poster
function videoPlay(wrapper) {
  const iframe = wrapper.find('iframe')
  wrapper.addClass('active')
  iframe.attr('src', iframe.attr('src') + '&autoplay=1')
}

function dhsv_vc_video() {
  $('.dhsv_vc_video').each(function() {
    $(this).on('click', '.js-video-poster', function(e) {
      e.preventDefault()
      const wrapper = $(this).closest('.js-videoWrapper')
      videoPlay(wrapper)
    })
  })
}
