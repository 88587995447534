import React from 'react'
import PropTypes from 'prop-types'
import { loadPosts } from '../../scripts/redux/modules/socials'
import { loadStrings } from '../../scripts/redux/modules/textstrings'
import Spinner from '../../scripts/components/Spinner'
import SocialItem from './SocialItem'

class Socials extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      postsShowed: 8,
      postsLoaded: 16,
      allPostsLoaded: false,
    }

    this.loadMoreSocials = this.loadMoreSocials.bind(this)
  }

  loadPosts(offset, per_page, show) {
    return this.props.dispatch(loadPosts(per_page, offset, show).bind(this))
  }

  loadStrings() {
    return this.props.dispatch(loadStrings().bind(this))
  }

  loadMoreSocials() {
    const { postsShowed, postsLoaded, allPostsLoaded } = this.state
    const { show } = this.props

    if (!allPostsLoaded) {
      new Promise(resolve => {
        resolve(this.loadPosts(postsLoaded, 8, show))
      }).then(value => {
        if (value.length) {
          this.setState({
            postsLoaded: postsLoaded + value.length,
          })
        } else {
          this.setState({
            allPostsLoaded: true,
          })
        }
      })
    }

    this.setState({
      postsShowed: postsShowed + 8,
    })
  }

  componentDidMount() {
    const { show } = this.props
    this.loadPosts(false, 16, show)
    this.loadStrings()
  }

  render() {
    const { error, posts, dataLoaded, strings, stringsLoaded } = this.props
    const { postsShowed, postsLoaded } = this.state

    if (error) {
      return <div>Error</div>
    }

    if (!dataLoaded && !stringsLoaded) {
      return <Spinner />
    }

    return (
      <div className="socials__container">
        <div className="row socials__list">
          {posts && posts.length
            ? posts
                .slice(0, postsShowed)
                .map((item, i) => <SocialItem key={i} item={item} />)
            : null}
        </div>
        <div className="row">
          <div className="col">
            {posts && posts.length && postsShowed < postsLoaded ? (
              <button
                className="btn btn-primary"
                onClick={() => this.loadMoreSocials()}
              >
                {strings.moreposts ? strings.moreposts : 'Mehr beiträge'}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

Socials.propTypes = {
  error: PropTypes.bool,
  dataLoaded: PropTypes.bool,
  dispatch: PropTypes.func,
  posts: PropTypes.array,
  strings: PropTypes.object,
  stringsLoaded: PropTypes.bool,
  id: PropTypes.string,
  show: PropTypes.string,
}

export default Socials
