import React from 'react'
import PropTypes from 'prop-types'
import SearchList from './data'

const Search = ({ s }) => {
  return <SearchList s={s} />
}

Search.propTypes = {
  s: PropTypes.string,
}

export default Search
