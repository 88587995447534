/* Don't forget to add string 'news' to function combineReducers in './news.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  posts: [],
}

const LOAD_SOCIALS_DATA = 'LOAD_SOCIALS_DATA'
const LOAD_SOCIALS_DATA_SUCCESS = 'LOAD_SOCIALS_DATA_SUCCESS'
const LOAD_SOCIALS_DATA_FAILURE = 'LOAD_SOCIALS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_SOCIALS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_SOCIALS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: [...state.posts, ...payload],
      }

    case LOAD_SOCIALS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const loadPosts = (per_page, offset, show) => dispatch => {
  dispatch({ type: LOAD_SOCIALS_DATA })
  // if (getState().news.isLoaded) return
  // console.log(`socials?offset=${offset}&per_page=${per_page}&show=${show}`) // eslint-disable-line

  return fetch(
    `${WP_API_BASE}socials?offset=${offset}&per_page=${per_page}&show=${show}`,
    {
      method: 'GET',
    }
  )
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        type: LOAD_SOCIALS_DATA_SUCCESS,
        payload,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_SOCIALS_DATA_FAILURE,
      })
    })
}
