import { connect } from 'react-redux'
import SocialsList from './SocialsList'

const WithData = connect(
  state => ({
    posts: state.socials.posts,
    error: state.socials.error,
    dataLoaded: state.socials.isLoaded,
    strings: state.textstrings.strings,
    stringsLoaded: state.textstrings.isLoaded,
  }),
  dispatch => ({ dispatch })
)(SocialsList)

export default WithData
