function counter() {
  const counter = $('.counter')

  if (
    counter.length &&
    $(window).scrollTop() + $(window).height() >
      counter.offset().top + counter.outerHeight() &&
    $(window).scrollTop() < counter.offset().top &&
    !counter.hasClass('counted')
  ) {
    counter.addClass('counted')
    $('.counter-number').each(function() {
      $(this)
        .prop('Counter', $(this).data('start'))
        .animate(
          {
            Counter: $(this).data('end'),
          },
          {
            duration: 2000,
            easing: 'swing',
            step: function(now) {
              $(this).text(Math.ceil(now))
            },
            complete: function() {
              $(this).addClass('animation-completed')
            },
          }
        )
    })
  }
}

$(document).load(() => {
  counter()
})

$(window).scroll(() => {
  counter()
})
