import $ from 'jquery'

export default function(id) {
  const $offsetItems = $('.set-sticky__holder')
  let offset = 0
  if ($offsetItems.length) {
    $offsetItems.each(function() {
      offset += $(this).outerHeight()
    })
  }

  $('html, body').animate(
    {
      scrollTop: $('#heading-' + id).offset().top - offset - 20,
    },
    800
  )
}
