import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class SearchItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { post } = this.props
    const { id, title, content, permalink, post_type } = post
    return (
      <article className={'search-item col-12 post-' + id}>
        <h2>
          <a href={permalink} className="search-item__link d-inline-flex">
            <span className="search-item__title d-inline-block">{title}</span>
            <span className="search-item__type d-inline-block color-warm_grey_50">
              ({post_type})
            </span>
          </a>
        </h2>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </article>
    )
  }
}

SearchItem.propTypes = {
  post: PropTypes.object,
  isMobile: PropTypes.bool,
}

export default SearchItem
