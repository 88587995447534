import React from 'react'
import PropTypes from 'prop-types'
import { loadStrings } from '../../redux/modules/textstrings'
import './style.scss'

class SimilarProjects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
      currentPage: 1,
      maxPages: 1,
      toggled: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  loadStrings() {
    return this.props.dispatch(loadStrings().bind(this))
  }

  handleClick() {
    this.setState({
      currentPage: this.state.currentPage + 1,
      toggled: !this.state.toggled,
    })
  }

  componentDidMount() {
    this.loadStrings()

    // Rest API Params
    const params = {}

    this.props.cat.term_id ? (params['cat_id'] = this.props.cat.term_id) : ''
    this.props.id ? (params['post_id'] = this.props.id) : ''
    let url_params = '/'
    if (params) {
      let i = 0
      for (let key in params) {
        params.hasOwnProperty(key)
          ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
          : false
        i++
      }
    }

    // Rest API Params
    fetch(`/wp-json/dhsv/v2/ffh/similar-projects${url_params}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.setState({
          projects: data,
          maxPages: Math.ceil(data.length / 3),
        })
      })
      .catch(function(err) {
        console.log('Error: ', err) // eslint-disable-line
      })
  }

  render() {
    const { cat, strings } = this.props
    return (
      <div className="projects pb-5 pt-5">
        <div className="container">
          {this.state.projects.length ? (
            <div className="row">
              <div className="col-md-3 text-primary">
                <p className="h2 t-transform--capitalize">{strings.weitere}</p>
                <p className="ff-baskerville h2">{cat.name}</p>
              </div>
              <div className="col-md-9 font-size-lg">
                {this.state.projects
                  .slice(0, this.state.currentPage * 3)
                  .map((project, i) => (
                    <div key={i} className="pb-4">
                      <p className="project__title">
                        <a href={project.permalink} className="text-primary">
                          {project.title}
                        </a>
                      </p>
                      <p className="project-content">{project.content}</p>
                    </div>
                  ))}
              </div>
              {this.state.maxPages > this.state.currentPage ? (
                <div className="col-md-9 offset-md-3">
                  <button
                    className="btn btn-blue btn-secondary"
                    onClick={this.handleClick}
                  >
                    {strings.mehr}
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="row">
              <div className="col-md-9 offset-md-3">{strings.no_data}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

SimilarProjects.propTypes = {
  id: PropTypes.string,
  cat: PropTypes.object,
  isMobile: PropTypes.bool,
  dispatch: PropTypes.func,
  strings: PropTypes.object,
}

export default SimilarProjects
