import $ from 'jquery'

export default function() {
  /*
   * Trimming excerpt text & adding dots after that
   */

  $('.socials__item-content').each(function(index, el) {
    let wordArray = el.innerHTML.split(' ')
    while (el.scrollHeight > $(el).data('text-rows') * 28) {
      wordArray.pop()
      el.innerHTML = wordArray.join(' ') + '...'
    }
  })
}
