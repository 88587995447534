import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { path, splitEvery } from 'ramda'
import { getCurriculumItem } from '../../scripts/redux/modules/curriculum'

class Courses extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedItem: false,
    }

    this.getCurriculumItem = this.getCurriculumItem.bind(this)
  }

  getCurriculumItem(id) {
    const { section_id } = this.props
    this.props.dispatch(getCurriculumItem(section_id, id))
    this.setState({
      selelectedItem: !this.state.selectedItem,
    })
  }

  render() {
    const { breakpoint, courses, selectedItem, section_id } = this.props
    const splitCounter = { xs: 1, sm: 1, md: 2, lg: 3, xl: 3, infinity: 4 }

    return (
      path(['length'], courses) && (
        <div className="curriculum__courses">
          {splitEvery(splitCounter[breakpoint], courses).map(
            (coursesRow, idx) => (
              <Fragment key={idx}>
                {coursesRow.map(course => (
                  <Fragment key={course.id}>
                    {course.title && (
                      <div
                        className={`curriculum__course ${
                          course.id === selectedItem[section_id] ? 'active' : ''
                        }`}
                        onClick={() => this.getCurriculumItem(course.id)}
                      >
                        <div className="course__title">{course.title}</div>
                      </div>
                    )}
                  </Fragment>
                ))}

                {selectedItem[section_id] &&
                  coursesRow.map(
                    (course, idx) =>
                      course.id === selectedItem[section_id] &&
                      (course.tags.length || course.ects) && (
                        <div key={idx} className="course__information active">
                          {course.information && course.information.length}
                          <div className="course__tags">
                            {course.tags &&
                              course.tags.length &&
                              course.tags.map((tag, idx) => (
                                <span className="tag" key={idx}>
                                  {tag}
                                </span>
                              ))}
                            <span className="tag">
                              {course.ects ? course.ects : 0} ECTS
                            </span>
                          </div>
                        </div>
                      )
                  )}
              </Fragment>
            )
          )}
        </div>
      )
    )
  }
}

Courses.propTypes = {
  section_id: PropTypes.string,
  courses: PropTypes.array,
  selectedItem: PropTypes.object,
  dispatch: PropTypes.func,
  breakpoint: PropTypes.string,
}

export default Courses
