/* Don't forget to add string 'news' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  strings: {},
}

const LOAD_STRINGS_DATA = 'LOAD_STRINGS_DATA'
const LOAD_STRINGS_DATA_SUCCESS = 'LOAD_STRINGS_DATA_SUCCESS'
const LOAD_STRINGS_DATA_FAILURE = 'LOAD_STRINGS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_STRINGS_DATA:
      return {
        ...state,
        strings: state,
        isLoading: true,
      }

    case LOAD_STRINGS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        strings: payload,
      }

    case LOAD_STRINGS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    default:
      return state
  }
}

export const loadStrings = () => (dispatch, getState) => {
  dispatch({ type: LOAD_STRINGS_DATA })

  if (getState().textstrings.isLoaded) return

  return fetch(`${WP_API_BASE}textstrings`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_STRINGS_DATA_SUCCESS,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_STRINGS_DATA_FAILURE,
      })
    })
}
