import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import curriculum from './curriculum'
import news from './news'
import search from './search'
import socials from './socials'
import jobs from './jobs'
import textstrings from './textstrings'

const appReducer = combineReducers({
  browser,
  location: router,
  curriculum,
  textstrings,
  news,
  socials,
  search,
  jobs,
})

export default appReducer
