import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SearchItem from './SearchItem'
import './style.scss'
import { loadStrings } from '../../redux/modules/textstrings'
import { loadPosts } from '../../redux/modules/search'
import Spinner from '../Spinner'

class SearchList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
      postsShowed: 10,
      postsLoaded: 20,
      allPostsLoaded: false,
    }
    this.loadMorePosts = this.loadMorePosts.bind(this)
  }

  loadPosts(s, offset, posts_per_page) {
    return this.props.dispatch(loadPosts(s, posts_per_page, offset).bind(this))
  }

  loadStrings() {
    return this.props.dispatch(loadStrings().bind(this))
  }

  loadMorePosts() {
    const { postsShowed, postsLoaded, allPostsLoaded } = this.state
    const { s } = this.props

    if (!allPostsLoaded) {
      new Promise(resolve => {
        resolve(this.loadPosts(s, postsLoaded, 10))
      }).then(({ posts }) => {
        if (posts.length) {
          this.setState({
            postsLoaded: postsLoaded + posts.length,
          })
        } else {
          this.setState({
            allPostsLoaded: true,
          })
        }
      })
    }
    this.setState({
      postsShowed: postsShowed + 10,
    })
  }

  componentDidMount() {
    this.loadStrings()
    const { s } = this.props
    this.loadPosts(s, false, 20)
  }

  render() {
    const { postsShowed, postsLoaded } = this.state
    const {
      s,
      error,
      strings,
      stringsLoaded,
      dataLoaded,
      posts,
      found_posts,
    } = this.props

    const displayedPosts = posts.slice(0, postsShowed).length
    const remainedPosts = found_posts - displayedPosts

    if (error) {
      return <div>Error</div>
    }

    if (!dataLoaded && !stringsLoaded) {
      return (
        <div className="mt-5 mb-5">
          <Spinner />
        </div>
      )
    }

    if (posts.length) {
      return (
        <div className="container pb-5 mb-5">
          <div className="row pt-5 mt-5 mb-5 color-warm_grey_50">
            <div className="col-12">
              <p className="pb-3 text-uppercase">
                {strings.search_phrase} &ldquo;<span className="text-primary">
                  {s}
                </span>
                &rdquo;:
              </p>
              <p className="text-uppercase">
                {displayedPosts}/{found_posts} {strings.are_shown}
              </p>
            </div>
          </div>
          <div className="row">
            {posts.slice(0, postsShowed).map((post, i) => {
              return <SearchItem key={i} post={post} />
            })}
          </div>
          {posts && posts.length >= 10 && postsShowed < postsLoaded ? (
            <div className="row mt-5">
              <div className="col-12 text-center">
                <button
                  className="btn search__btn-load"
                  onClick={() => this.loadMorePosts()}
                >
                  {remainedPosts > 9
                    ? strings.more +
                      ' 10 ' +
                      strings.von +
                      ' ' +
                      found_posts +
                      ' ' +
                      strings.ergbenissen_laden
                    : strings.weitere_ergebnisse_laden}
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )
    } else {
      return ''
    }
  }
}

SearchList.propTypes = {
  error: PropTypes.bool,
  posts: PropTypes.array,
  found_posts: PropTypes.number,
  s: PropTypes.string,
  strings: PropTypes.object,
  stringsLoaded: PropTypes.bool,
  dataLoaded: PropTypes.bool,
  dispatch: PropTypes.func,
  isMobile: PropTypes.bool,
}

export default SearchList
