/* Don't forget to add string 'curriculum' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  semesters: {},
  selectedItem: {},
}

const LOAD_CURRICULUM_DATA = 'LOAD_CURRICULUM_DATA'
const LOAD_CURRICULUM_DATA_SUCCESS = 'LOAD_CURRICULUM_DATA_SUCCESS'
const LOAD_CURRICULUM_DATA_FAILURE = 'LOAD_CURRICULUM_DATA_FAILURE'
const GET_CURRICULUM_ITEM = 'GET_CURRICULUM_ITEM'

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_CURRICULUM_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_CURRICULUM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        semesters: { ...state.semesters, ...payload },
      }

    case LOAD_CURRICULUM_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case GET_CURRICULUM_ITEM:
      return {
        ...state,
        isLoading: false,
        selectedItem: payload,
      }

    default:
      return state
  }
}

export const loadPosts = (section_id, id) => (dispatch, getState) => {
  dispatch({ type: LOAD_CURRICULUM_DATA })

  if (getState().curriculum.isLoaded) return

  return fetch(`${WP_API_BASE}curriculum?section_id=${section_id}&id=${id}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_CURRICULUM_DATA_SUCCESS,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_CURRICULUM_DATA_FAILURE,
      })
    })
}

export const getCurriculumItem = (section_id, id) => (dispatch, getState) => {
  let selectedItem = getState().curriculum.selectedItem
  if (selectedItem[section_id] == id) {
    delete selectedItem[section_id]
  } else {
    selectedItem[section_id] = id
  }

  return dispatch({
    type: GET_CURRICULUM_ITEM,
    payload: selectedItem,
  })
}
