import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import Spinner from '../../scripts/components/Spinner'
import { loadPosts } from '../../scripts/redux/modules/curriculum'
import { loadStrings } from '../../scripts/redux/modules/textstrings'
import Semesters from './Semesters'

class Curriculum extends React.Component {
  loadPosts() {
    const { section_id, post_id } = this.props
    return this.props.dispatch(loadPosts(section_id, post_id).bind(this))
  }

  loadStrings() {
    return this.props.dispatch(loadStrings().bind(this))
  }

  componentDidMount() {
    this.loadStrings()
    this.loadPosts()
  }

  render() {
    const { error, semesters, dataLoaded, strings, section_id } = this.props

    if (error) {
      return <div>Error</div>
    }

    if (!dataLoaded || !strings) {
      return <Spinner />
    }

    return (
      <Fragment>
        {path(['length'], semesters[section_id]) ? (
          <Semesters {...this.props} />
        ) : (
          strings.no_data
        )}
      </Fragment>
    )
  }
}

Curriculum.propTypes = {
  error: PropTypes.bool,
  semesters: PropTypes.object,
  dataLoaded: PropTypes.bool,
  dispatch: PropTypes.func,
  loadPosts: PropTypes.func,
  id: PropTypes.string,
  section_id: PropTypes.string,
  post_id: PropTypes.string,
  strings: PropTypes.object,
}

export default Curriculum
