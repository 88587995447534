import $ from 'jquery'
import lottie from 'lottie-web'
import * as building0Data from './animationData/building0.json'
import * as building1Data from './animationData/building1.json'
import * as building2Data from './animationData/building2.json'
import * as building3Data from './animationData/building3.json'
import * as bgData from './animationData/bg.json'
import * as level0Data from './animationData/level0.json'
import * as level1Data from './animationData/level1.json'
import * as level2Data from './animationData/level2.json'
import * as level3Data from './animationData/level3.json'
import * as level4Data from './animationData/level4.json'

$(document).ready(function() {
  dhsv_vc_campus_plan()
})

function dhsv_vc_campus_plan() {
  $('.dhsv_vc_campus_plan').each(function() {
    var divContainer = document.getElementById('bm')
    var divContainerBackground = document.getElementById('bg')
    var renderSetting = 'xMidYMid'

    var building0Params = {
      container: divContainer,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: building0Data.default,
      rendererSettings: {
        preserveAspectRatio: renderSetting,
      },
    }

    var building1Params = {
      container: divContainer,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: building1Data.default,
      rendererSettings: {
        preserveAspectRatio: renderSetting,
      },
    }

    var building2Params = {
      container: divContainer,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: building2Data.default,
      rendererSettings: {
        preserveAspectRatio: renderSetting,
      },
    }

    var building3Params = {
      container: divContainer,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: building3Data.default,
      rendererSettings: {
        preserveAspectRatio: renderSetting,
      },
    }

    var bgParams = {
      container: divContainerBackground,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: bgData.default,
      rendererSettings: {
        preserveAspectRatio: renderSetting,
      },
    }

    var introSegment = [0, 26]
    var speed = 2
    var building0 = lottie.loadAnimation(building0Params)
    var building1 = lottie.loadAnimation(building1Params)
    var building2 = lottie.loadAnimation(building2Params)
    var building3 = lottie.loadAnimation(building3Params)

    lottie.loadAnimation(bgParams)

    var el = $('#bm')

    el.on('mouseenter', '.building0', function() {
      building0.setSpeed(speed)
      building0.playSegments(introSegment, true)
    })
    el.on('mouseleave', '.building0', function() {
      building0.setDirection(-1)
      building0.play()
    })

    el.on('mouseenter', '.building1', function() {
      building1.setSpeed(speed)
      building1.playSegments(introSegment, true)
    })
    el.on('mouseleave', '.building1', function() {
      building1.setDirection(-1)
      building1.play()
    })

    el.on('mouseenter', '.building2', function() {
      building2.setSpeed(speed)
      building2.playSegments(introSegment, true)
    })
    el.on('mouseleave', '.building2', function() {
      building2.setDirection(-1)
      building2.play()
    })

    el.on('mouseenter', '.building3', function() {
      building3.setSpeed(speed)
      building3.playSegments(introSegment, true)
    })

    el.on('mouseleave', '.building3', function() {
      building3.setDirection(-1)
      building3.play()
    })

    var $overlay = $('.overlay')
    var $level1 = $('#level1')
    var navEl = $('.room_navigation')
    var levels = $('.level')
    var lists = $('.list')
    var navLabels = $('.level-label')

    el.on('click', '.building1', function() {
      if ($overlay.hasClass('inactive')) {
        $overlay.removeClass('inactive')
      }

      if ($level1.hasClass('inactive')) {
        $level1.removeClass('inactive')
      }

      if ($('.level1_list').hasClass('inactive')) {
        $('.level1_list').removeClass('inactive')
      }

      navLabels.each(function() {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active')
        }
      })

      if (!$('.label1').hasClass('active')) {
        $('.label1').addClass('active')
      }
    })

    /** navigation */

    $overlay.on('click', '.close', function() {
      if (!$overlay.hasClass('inactive')) {
        $overlay.addClass('inactive')
      }
      levels.each(function() {
        if (!$(this).hasClass('inactive')) {
          $(this).addClass('inactive')
        }
      })

      lists.each(function() {
        if (!$(this).hasClass('inactive')) {
          $(this).addClass('inactive')
        }
      })
    })

    navEl.on('click', '.label0', function() {
      var level = $('#level0')
      var list = $('.level0_list')

      levels.each(function() {
        $(this).addClass('inactive')
      })

      lists.each(function() {
        $(this).addClass('inactive')
      })

      if (level.hasClass('inactive')) {
        level.removeClass('inactive')
      }

      if (list.hasClass('inactive')) {
        list.removeClass('inactive')
      }

      navLabels.each(function() {
        $(this).removeClass('active')
      })

      if (!$('.label0').hasClass('active')) {
        $(this).addClass('active')
      }
    })

    navEl.on('click', '.label1', function() {
      var level = $('#level1')
      var list = $('.level1_list')

      levels.each(function() {
        $(this).addClass('inactive')
      })

      lists.each(function() {
        $(this).addClass('inactive')
      })

      if (level.hasClass('inactive')) {
        level.removeClass('inactive')
      }

      if (list.hasClass('inactive')) {
        list.removeClass('inactive')
      }

      navLabels.each(function() {
        $(this).removeClass('active')
      })

      if (!$('.label1').hasClass('active')) {
        $(this).addClass('active')
      }
    })

    navEl.on('click', '.label2', function() {
      var level = $('#level2')
      var list = $('.level2_list')

      levels.each(function() {
        $(this).addClass('inactive')
      })

      lists.each(function() {
        $(this).addClass('inactive')
      })

      if (level.hasClass('inactive')) {
        level.removeClass('inactive')
      }

      if (list.hasClass('inactive')) {
        list.removeClass('inactive')
      }

      navLabels.each(function() {
        $(this).removeClass('active')
      })

      if (!$('.label2').hasClass('active')) {
        $(this).addClass('active')
      }
    })

    navEl.on('click', '.label3', function() {
      var level = $('#level3')
      var list = $('.level3_list')

      levels.each(function() {
        $(this).addClass('inactive')
      })

      lists.each(function() {
        $(this).addClass('inactive')
      })

      if (level.hasClass('inactive')) {
        level.removeClass('inactive')
      }

      if (list.hasClass('inactive')) {
        list.removeClass('inactive')
      }

      navLabels.each(function() {
        $(this).removeClass('active')
      })

      if (!$('.label3').hasClass('active')) {
        $(this).addClass('active')
      }
    })

    navEl.on('click', '.label4', function() {
      var level = $('#level4')
      var list = $('.level4_list')

      levels.each(function() {
        $(this).addClass('inactive')
      })

      lists.each(function() {
        $(this).addClass('inactive')
      })

      if (level.hasClass('inactive')) {
        level.removeClass('inactive')
      }

      if (list.hasClass('inactive')) {
        list.removeClass('inactive')
      }

      navLabels.each(function() {
        $(this).removeClass('active')
      })

      if (!$('.label4').hasClass('active')) {
        $(this).addClass('active')
      }
    })

    /** Lottie for Levels */

    var divContainerLevel0 = document.getElementById('level0')
    var divContainerLevel1 = document.getElementById('level1')
    var divContainerLevel2 = document.getElementById('level2')
    var divContainerLevel3 = document.getElementById('level3')
    var divContainerLevel4 = document.getElementById('level4')
    var list0 = $('.level0_list')
    var list1 = $('.level1_list')
    var list2 = $('.level2_list')
    var list3 = $('.level3_list')
    var list4 = $('.level4_list')
    var level0El = $('#level0')
    var level1El = $('#level1')
    var level2El = $('#level2')
    var level3El = $('#level3')
    var level4El = $('#level4')

    var level0Params = {
      container: divContainerLevel0,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: level0Data.default,
    }

    var level1Params = {
      container: divContainerLevel1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: level1Data.default,
    }

    var level2Params = {
      container: divContainerLevel2,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: level2Data.default,
    }
    var level3Params = {
      container: divContainerLevel3,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: level3Data.default,
    }

    var level4Params = {
      container: divContainerLevel4,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: level4Data.default,
    }

    lottie.loadAnimation(level0Params)
    lottie.loadAnimation(level1Params)
    lottie.loadAnimation(level2Params)
    lottie.loadAnimation(level3Params)
    lottie.loadAnimation(level4Params)

    /** Level0 handling */

    level0El.on('mouseenter', '#level0room0', function() {
      $('#level0room0label').addClass('highlight')
    })

    level0El.on('mouseleave', '#level0room0', function() {
      $('#level0room0label').removeClass('highlight')
    })

    level0El.on('mouseenter', '#level0room1', function() {
      $('#level0room1label').addClass('highlight')
    })

    level0El.on('mouseleave', '#level0room1', function() {
      $('#level0room1label').removeClass('highlight')
    })

    level0El.on('mouseenter', '#level0room2', function() {
      $('#level0room2label').addClass('highlight')
    })

    level0El.on('mouseleave', '#level0room2', function() {
      $('#level0room2label').removeClass('highlight')
    })

    /** connecting Level0 list items with rooms */

    list0.on('mouseenter', '#level0room0label', function() {
      $('#level0room0label').addClass('highlight')
      $('#level0room0').addClass('roomHighlight')
    })

    list0.on('mouseleave', '#level0room0label', function() {
      $('#level0room0label').removeClass('highlight')
      $('#level0room0').removeClass('roomHighlight')
    })

    list0.on('mouseenter', '#level0room1label', function() {
      $('#level0room1label').addClass('highlight')
      $('#level0room1').addClass('roomHighlight')
    })

    list0.on('mouseleave', '#level0room1label', function() {
      $('#level0room1label').removeClass('highlight')
      $('#level0room1').removeClass('roomHighlight')
    })

    list0.on('mouseenter', '#level0room2label', function() {
      $('#level0room2label').addClass('highlight')
      $('#level0room2').addClass('roomHighlight')
    })

    list0.on('mouseleave', '#level0room2label', function() {
      $('#level0room2label').removeClass('highlight')
      $('#level0room2').removeClass('roomHighlight')
    })

    /** Level1 handling */

    level1El.on('mouseenter', '#level1room0', function() {
      $('#level1room0label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room0', function() {
      $('#level1room0label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room1', function() {
      $('#level1room1label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room1', function() {
      $('#level1room1label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room2', function() {
      $('#level1room2label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room2', function() {
      $('#level1room2label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room3', function() {
      $('#level1room3label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room3', function() {
      $('#level1room3label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room4', function() {
      $('#level1room4label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room4', function() {
      $('#level1room4label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room5', function() {
      $('#level1room5label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room5', function() {
      $('#level1room5label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room6', function() {
      $('#level1room6label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room6', function() {
      $('#level1room6label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room7', function() {
      $('#level1room7label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room7', function() {
      $('#level1room7label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room8', function() {
      $('#level1room8label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room8', function() {
      $('#level1room8label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room9', function() {
      $('#level1room9label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room9', function() {
      $('#level1room9label').removeClass('highlight')
    })

    level1El.on('mouseenter', '#level1room10', function() {
      $('#level1room10label').addClass('highlight')
    })

    level1El.on('mouseleave', '#level1room10', function() {
      $('#level1room10label').removeClass('highlight')
    })

    /** connecting Level1 list items with rooms */

    list1.on('mouseenter', '#level1room0label', function() {
      $('#level1room0label').addClass('highlight')
      $('#level1room0').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room0label', function() {
      $('#level1room0label').removeClass('highlight')
      $('#level1room0').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room1label', function() {
      $('#level1room1label').addClass('highlight')
      $('#level1room1').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room1label', function() {
      $('#level1room1label').removeClass('highlight')
      $('#level1room1').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room2label', function() {
      $('#level1room2label').addClass('highlight')
      $('#level1room2').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room2label', function() {
      $('#level1room2label').removeClass('highlight')
      $('#level1room2').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room3label', function() {
      $('#level1room3label').addClass('highlight')
      $('#level1room3').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room3label', function() {
      $('#level1room3label').removeClass('highlight')
      $('#level1room3').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room4label', function() {
      $('#level1room4label').addClass('highlight')
      $('#level1room4').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room4label', function() {
      $('#level1room4label').removeClass('highlight')
      $('#level1room4').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room5label', function() {
      $('#level1room5label').addClass('highlight')
      $('#level1room5').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room5label', function() {
      $('#level1room5label').removeClass('highlight')
      $('#level1room5').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room6label', function() {
      $('#level1room6label').addClass('highlight')
      $('#level1room6').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room6label', function() {
      $('#level1room6label').removeClass('highlight')
      $('#level1room6').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room7label', function() {
      $('#level1room7label').addClass('highlight')
      $('#level1room7').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room7label', function() {
      $('#level1room7label').removeClass('highlight')
      $('#level1room7').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room8label', function() {
      $('#level1room8label').addClass('highlight')
      $('#level1room8').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room8label', function() {
      $('#level1room8label').removeClass('highlight')
      $('#level1room8').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room9label', function() {
      $('#level1room9label').addClass('highlight')
      $('#level1room9').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room9label', function() {
      $('#level1room9label').removeClass('highlight')
      $('#level1room9').removeClass('roomHighlight')
    })

    list1.on('mouseenter', '#level1room10label', function() {
      $('#level1room10label').addClass('highlight')
      $('#level1room10').addClass('roomHighlight')
    })

    list1.on('mouseleave', '#level1room10label', function() {
      $('#level1room10label').removeClass('highlight')
      $('#level1room10').removeClass('roomHighlight')
    })

    /** Level2 handling */

    level2El.on('mouseenter', '#level2room0', function() {
      $('#level2room0label').addClass('highlight')
    })

    level2El.on('mouseleave', '#level2room0', function() {
      $('#level2room0label').removeClass('highlight')
    })

    level2El.on('mouseenter', '#level2room1', function() {
      $('#level2room1label').addClass('highlight')
    })

    level2El.on('mouseleave', '#level2room1', function() {
      $('#level2room1label').removeClass('highlight')
    })

    level2El.on('mouseenter', '#level2room2', function() {
      $('#level2room2label').addClass('highlight')
    })

    level2El.on('mouseleave', '#level2room2', function() {
      $('#level2room2label').removeClass('highlight')
    })

    level2El.on('mouseenter', '#level2room3', function() {
      $('#level2room3label').addClass('highlight')
    })

    level2El.on('mouseleave', '#level2room3', function() {
      $('#level2room3label').removeClass('highlight')
    })

    level2El.on('mouseenter', '#level2room4', function() {
      $('#level2room4label').addClass('highlight')
    })

    level2El.on('mouseleave', '#level2room4', function() {
      $('#level2room4label').removeClass('highlight')
    })

    level2El.on('mouseenter', '#level2room5', function() {
      $('#level2room5label').addClass('highlight')
    })

    level2El.on('mouseleave', '#level2room5', function() {
      $('#level2room5label').removeClass('highlight')
    })

    /** connecting Level2 list items with rooms */

    list2.on('mouseenter', '#level2room0label', function() {
      $('#level2room0label').addClass('highlight')
      $('#level2room0').addClass('roomHighlight')
    })

    list2.on('mouseleave', '#level2room0label', function() {
      $('#level2room0label').removeClass('highlight')
      $('#level2room0').removeClass('roomHighlight')
    })

    list2.on('mouseenter', '#level2room1label', function() {
      $('#level2room1label').addClass('highlight')
      $('#level2room1').addClass('roomHighlight')
    })

    list2.on('mouseleave', '#level2room1label', function() {
      $('#level2room1label').removeClass('highlight')
      $('#level2room1').removeClass('roomHighlight')
    })

    list2.on('mouseenter', '#level2room2label', function() {
      $('#level2room2label').addClass('highlight')
      $('#level2room2').addClass('roomHighlight')
    })

    list2.on('mouseleave', '#level2room2label', function() {
      $('#level2room2label').removeClass('highlight')
      $('#level2room2').removeClass('roomHighlight')
    })

    list2.on('mouseenter', '#level2room3label', function() {
      $('#level2room3label').addClass('highlight')
      $('#level2room3').addClass('roomHighlight')
    })

    list2.on('mouseleave', '#level2room3label', function() {
      $('#level2room3label').removeClass('highlight')
      $('#level2room3').removeClass('roomHighlight')
    })

    list2.on('mouseenter', '#level2room4label', function() {
      $('#level2room4label').addClass('highlight')
      $('#level2room4').addClass('roomHighlight')
    })

    list2.on('mouseleave', '#level2room4label', function() {
      $('#level2room4label').removeClass('highlight')
      $('#level2room4').removeClass('roomHighlight')
    })

    list2.on('mouseenter', '#level2room5label', function() {
      $('#level2room5label').addClass('highlight')
      $('#level2room5').addClass('roomHighlight')
    })

    list2.on('mouseleave', '#level2room5label', function() {
      $('#level2room5label').removeClass('highlight')
      $('#level2room5').removeClass('roomHighlight')
    })

    /** Level3 handling */

    level3El.on('mouseenter', '#level3room0', function() {
      $('#level3room0label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room0', function() {
      $('#level3room0label').removeClass('highlight')
    })

    level3El.on('mouseenter', '#level3room1', function() {
      $('#level3room1label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room1', function() {
      $('#level3room1label').removeClass('highlight')
    })

    level3El.on('mouseenter', '#level3room2', function() {
      $('#level3room2label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room2', function() {
      $('#level3room2label').removeClass('highlight')
    })

    level3El.on('mouseenter', '#level3room3', function() {
      $('#level3room3label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room3', function() {
      $('#level3room3label').removeClass('highlight')
    })

    level3El.on('mouseenter', '#level3room4', function() {
      $('#level3room4label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room4', function() {
      $('#level3room4label').removeClass('highlight')
    })

    level3El.on('mouseenter', '#level3room5', function() {
      $('#level3room5label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room5', function() {
      $('#level3room5label').removeClass('highlight')
    })

    level3El.on('mouseenter', '#level3room6', function() {
      $('#level3room6label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room6', function() {
      $('#level3room6label').removeClass('highlight')
    })

    level3El.on('mouseenter', '#level3room7', function() {
      $('#level3room7label').addClass('highlight')
    })

    level3El.on('mouseleave', '#level3room7', function() {
      $('#level3room7label').removeClass('highlight')
    })

    /** connecting Level3 list items with rooms */

    list3.on('mouseenter', '#level3room0label', function() {
      $('#level3room0label').addClass('highlight')
      $('#level3room0').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room0label', function() {
      $('#level3room0label').removeClass('highlight')
      $('#level3room0').removeClass('roomHighlight')
    })

    list3.on('mouseenter', '#level3room1label', function() {
      $('#level3room1label').addClass('highlight')
      $('#level3room1').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room1label', function() {
      $('#level3room1label').removeClass('highlight')
      $('#level3room1').removeClass('roomHighlight')
    })

    list3.on('mouseenter', '#level3room2label', function() {
      $('#level3room2label').addClass('highlight')
      $('#level3room2').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room2label', function() {
      $('#level3room2label').removeClass('highlight')
      $('#level3room2').removeClass('roomHighlight')
    })

    list3.on('mouseenter', '#level3room3label', function() {
      $('#level3room3label').addClass('highlight')
      $('#level3room3').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room3label', function() {
      $('#level3room3label').removeClass('highlight')
      $('#level3room3').removeClass('roomHighlight')
    })

    list3.on('mouseenter', '#level3room4label', function() {
      $('#level3room4label').addClass('highlight')
      $('#level3room4').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room4label', function() {
      $('#level3room4label').removeClass('highlight')
      $('#level3room4').removeClass('roomHighlight')
    })

    list3.on('mouseenter', '#level3room5label', function() {
      $('#level3room5label').addClass('highlight')
      $('#level3room5').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room5label', function() {
      $('#level3room5label').removeClass('highlight')
      $('#level3room5').removeClass('roomHighlight')
    })

    list3.on('mouseenter', '#level3room6label', function() {
      $('#level3room6label').addClass('highlight')
      $('#level3room6').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room6label', function() {
      $('#level3room6label').removeClass('highlight')
      $('#level3room6').removeClass('roomHighlight')
    })

    list3.on('mouseenter', '#level3room7label', function() {
      $('#level3room7label').addClass('highlight')
      $('#level3room7').addClass('roomHighlight')
    })

    list3.on('mouseleave', '#level3room7label', function() {
      $('#level3room7label').removeClass('highlight')
      $('#level3room7').removeClass('roomHighlight')
    })

    /** Level4 handling */

    level4El.on('mouseenter', '#level4room0', function() {
      $('#level4room0label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room0', function() {
      $('#level4room0label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room1', function() {
      $('#level4room1label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room1', function() {
      $('#level4room1label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room2', function() {
      $('#level4room2label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room2', function() {
      $('#level4room2label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room3', function() {
      $('#level4room3label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room3', function() {
      $('#level4room3label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room4', function() {
      $('#level4room4label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room4', function() {
      $('#level4room4label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room5', function() {
      $('#level4room5label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room5', function() {
      $('#level4room5label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room6', function() {
      $('#level4room6label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room6', function() {
      $('#level4room6label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room7', function() {
      $('#level4room7label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room7', function() {
      $('#level4room7label').removeClass('highlight')
    })

    level4El.on('mouseenter', '#level4room8', function() {
      $('#level4room8label').addClass('highlight')
    })

    level4El.on('mouseleave', '#level4room8', function() {
      $('#level4room8label').removeClass('highlight')
    })

    /** connecting Level4 list items with rooms */

    list4.on('mouseenter', '#level4room0label', function() {
      $('#level4room0label').addClass('highlight')
      $('#level4room0').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room0label', function() {
      $('#level4room0label').removeClass('highlight')
      $('#level4room0').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room1label', function() {
      $('#level4room1label').addClass('highlight')
      $('#level4room1').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room1label', function() {
      $('#level4room1label').removeClass('highlight')
      $('#level4room1').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room2label', function() {
      $('#level4room2label').addClass('highlight')
      $('#level4room2').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room2label', function() {
      $('#level4room2label').removeClass('highlight')
      $('#level4room2').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room3label', function() {
      $('#level4room3label').addClass('highlight')
      $('#level4room3').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room3label', function() {
      $('#level4room3label').removeClass('highlight')
      $('#level4room3').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room4label', function() {
      $('#level4room4label').addClass('highlight')
      $('#level4room4').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room4label', function() {
      $('#level4room4label').removeClass('highlight')
      $('#level4room4').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room5label', function() {
      $('#level4room5label').addClass('highlight')
      $('#level4room5').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room5label', function() {
      $('#level4room5label').removeClass('highlight')
      $('#level4room5').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room6label', function() {
      $('#level4room6label').addClass('highlight')
      $('#level4room6').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room6label', function() {
      $('#level4room6label').removeClass('highlight')
      $('#level4room6').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room7label', function() {
      $('#level4room7label').addClass('highlight')
      $('#level4room7').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room7label', function() {
      $('#level4room7label').removeClass('highlight')
      $('#level4room7').removeClass('roomHighlight')
    })

    list4.on('mouseenter', '#level4room8label', function() {
      $('#level4room8label').addClass('highlight')
      $('#level4room8').addClass('roomHighlight')
    })

    list4.on('mouseleave', '#level4room8label', function() {
      $('#level4room8label').removeClass('highlight')
      $('#level4room8').removeClass('roomHighlight')
    })
  })
}
