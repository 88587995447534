import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Courses from './Courses'
import { path, sum } from 'ramda'

class Semesters extends Component {
  render() {
    const {
      browser: { mediaType },
      strings,
      semesters,
      section_id,
      selectedItem,
    } = this.props

    const ectsSum = sum(
      semesters[section_id]
        .map(semester => {
          return semester.map(module => module.ects)
        })
        .reduce((acc, val) => acc.concat(val), [])
    )

    return (
      <Fragment>
        {path(['length'], semesters[section_id]) && (
          <div className="curriculum__heading">
            <div className="curriculum__heading-left">{strings.semester}</div>
            <div className="curriculum__heading-center">{strings.module}</div>
            <div className="curriculum__heading-right d-none d-sm-block">
              {strings.points}
            </div>
          </div>
        )}

        {path(['length'], semesters[section_id]) &&
          semesters[section_id].map((semester, index) => {
            return (
              <Fragment key={index}>
                <div className="curriculum__body-wrapper" key={index}>
                  {path(['length'], semester) &&
                    semester.map((module, idx) => (
                      <div key={idx} className="curriculum__body">
                        <div className="curriculum__body-left">
                          {idx === 0 ? `${index + 1}.` : ``}
                        </div>
                        <div className="curriculum__body-center">
                          <div key={idx} className="curriculum__module">
                            <div className="module__title">{module.title}</div>
                            {path(['courses', 'length'], module) && (
                              <Courses
                                section_id={section_id}
                                courses={module.courses}
                                breakpoint={mediaType}
                                selectedItem={selectedItem}
                                dispatch={this.props.dispatch}
                              />
                            )}
                          </div>
                        </div>

                        <div className="curriculum__body-right">
                          <div>
                            <span className="points d-inline-block d-sm-none">
                              {strings.points}
                            </span>

                            <span>
                              <span className="big-text">{module.ects}</span>{' '}
                              {strings.ects}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Fragment>
            )
          })}

        {path(['length'], semesters[section_id]) && (
          <div className="curriculum__footer">
            <div className="curriculum__footer-left" />
            <div className="curriculum__footer-center" />
            <div className="curriculum__footer-right">
              <span className="big-text">{ectsSum}</span> {strings.ects}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

Semesters.propTypes = {
  semesters: PropTypes.object,
  section_id: PropTypes.string,
  selectedItem: PropTypes.object,
  dispatch: PropTypes.func,
  mediaType: PropTypes.string,
  browser: PropTypes.object,
  strings: PropTypes.object,
}

export default Semesters
