import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_intro_section()
})

$(window).resize(function() {
  dhsv_vc_intro_section()
})

function dhsv_vc_intro_section() {
  const bannerHeight = $('.banner').outerHeight()
  const windowHeight = $(window).height()
  $('.js-intro-section').each(function() {
    $(this).css('min-height', windowHeight - bannerHeight)
  })
}
