/* Don't forget to add string 'staff' to function combineReducers in './staff.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  posts: {},
}

const LOAD_JOBS_DATA = 'LOAD_JOBS_DATA'
const LOAD_JOBS_DATA_SUCCESS = 'LOAD_JOBS_DATA_SUCCESS'
const LOAD_JOBS_DATA_FAILURE = 'LOAD_JOBS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_JOBS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_JOBS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: { ...state.posts, ...payload },
      }

    case LOAD_JOBS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const loadPosts = (id, category, per_page, offset) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_JOBS_DATA })

  // if (getState().jobs.isLoaded) return
  let currentJobs = getState().jobs.posts[id]
  if (currentJobs === undefined) {
    currentJobs = new Array()
  }

  return fetch(
    `${WP_API_BASE}jobs?offset=${offset}&per_page=${per_page}&category=${category}`,
    {
      method: 'GET',
    }
  )
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(posts => {
      const payload = {}
      payload[id] = currentJobs.concat(posts)

      dispatch({
        type: LOAD_JOBS_DATA_SUCCESS,
        payload,
      })
      return posts
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_JOBS_DATA_FAILURE,
      })
    })
}
