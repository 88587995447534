//import this file to main.js file after bootstrap-toolkit.js file - import './components/empty-space'
// get current bootstrap breakpoint
// import './util/bootstrap-toolkit'
// get vc_empty_space handler
// import './components/empty-space'

function dhsv_vc_empty_space() {
  $('.vc_empty_space').each(function(idx, emptySpace) {
    const $emptySpace = $(emptySpace)
    const currentBreakpoint = window.bootstrapToolkit.current()

    if (currentBreakpoint) {
      const breakpoint = $emptySpace.data(currentBreakpoint)
        ? $emptySpace.data(currentBreakpoint)
        : $emptySpace.data('md')

      $emptySpace.css('height', breakpoint)
    }
  })
}

$(document).ready(function() {
  dhsv_vc_empty_space()
})

$(window).resize(function() {
  dhsv_vc_empty_space()
})
