import React from 'react'
import PropTypes from 'prop-types'
import EventCard from './EventCard'
import { loadStrings } from '../../scripts/redux/modules/textstrings'

class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      currentPage: 1,
      maxPages: 1,
      toggled: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  loadStrings() {
    return this.props.dispatch(loadStrings().bind(this))
  }

  handleClick() {
    this.setState({
      currentPage: this.state.currentPage + 1,
      toggled: !this.state.toggled,
    })
  }

  componentDidMount() {
    this.loadStrings()

    // Rest API Params
    const params = {}
    const { ids, all } = this.props

    ids ? (params['ids'] = ids) : ''
    all ? (params['all'] = all) : ''
    let url_params = '/'
    if (params) {
      let i = 0
      for (let key in params) {
        params.hasOwnProperty(key)
          ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
          : false
        i++
      }
    }

    // Rest API Params
    fetch(`/wp-json/dhsv/v2/ffh/event${url_params}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        const { activeid } = this.props
        const activeIndex = data.findIndex(el => el.id == activeid)
        let currentPage = 1
        if (activeIndex !== -1 && activeIndex >= 15) {
          currentPage = Math.ceil((activeIndex + 1) / 15)
        }
        this.setState({
          events: data,
          maxPages: Math.ceil(data.length / 15),
          currentPage,
        })
      })
      .catch(function(err) {
        console.log('Error: ', err) // eslint-disable-line
      })
  }

  render() {
    const { events, currentPage } = this.state
    const { strings, activeid } = this.props
    let currentMonth = ''
    let showMonth = true
    const targetEvents = events.slice(0, currentPage * 15)
    // eslint-disable-next-line no-console
    console.log(events)

    return (
      <div>
        {events.length ? (
          <div className="accordion" id="eventsAccordion">
            {targetEvents.map((event, i) => {
              const isoDate = event.iso_date
              const date = new Date(isoDate)
              const monthName = date.toLocaleString('default', {
                month: 'long',
              })

              if (currentMonth != monthName) {
                currentMonth = monthName
                showMonth = true
              } else {
                showMonth = false
              }
              return (
                <EventCard
                  key={i}
                  {...{ event, strings, i, showMonth, activeid }}
                />
              )
            })}
            {this.state.maxPages > this.state.currentPage ? (
              <div className="mt-5 pb-2">
                <button
                  className="btn js-more-events"
                  onClick={this.handleClick}
                >
                  {strings.alle_termine}
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

Events.propTypes = {
  all: PropTypes.string,
  ids: PropTypes.string,
  activeid: PropTypes.string,
  strings: PropTypes.object,
  dispatch: PropTypes.func,
}

export default Events
