// import external dependencies
import 'jquery'

// get current bootstrap breakpoint
import './util/bootstrap-toolkit'

// get vc_empty_space handler
import './components/empty-space'

// import everything from autoload
import './autoload/_bootstrap.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/advantages/main.js'; import '../vc_elements/aktuelles/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/campus_plan/main.js'; import '../vc_elements/clusters/main.js'; import '../vc_elements/contact_intro/main.js'; import '../vc_elements/counter/main.js'; import '../vc_elements/events/main.js'; import '../vc_elements/flip_box/main.js'; import '../vc_elements/hero_section/main.js'; import '../vc_elements/history/main.js'; import '../vc_elements/info_icons/main.js'; import '../vc_elements/intro_section/main.js'; import '../vc_elements/onlineform/main.js'; import '../vc_elements/page_teaser/main.js'; import '../vc_elements/partners_gallery/main.js'; import '../vc_elements/projects/main.js'; import '../vc_elements/recent_news/main.js'; import '../vc_elements/related_news/main.js'; import '../vc_elements/share_buttons/main.js'; import '../vc_elements/teaser_box/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/testimonials_slider/main.js'; import '../vc_elements/text_spoiler/main.js'; import '../vc_elements/video/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
