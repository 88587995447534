import { connect } from 'react-redux'
import Component from './component'
import PropTypes from 'prop-types'

const WithData = connect(
  state => ({
    strings: state.textstrings.strings,
  }),
  dispatch => ({ dispatch })
)(Component)

WithData.propTypes = {
  isMobile: PropTypes.bool,
}

export default WithData
