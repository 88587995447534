import { createResponsiveStateReducer } from 'redux-responsive'

// bootstrap $grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 1025px, xl: 1440px, xxl: 1920px);
export default createResponsiveStateReducer({
  xs: 575,
  sm: 767,
  md: 1024,
  lg: 1199,
  xl: 1439,
})

export const sIsMobile = state => state.browser.lessThan.sm
