import { connect } from 'react-redux'
import JobsList from './JobsList'

const WithData = connect(
  state => ({
    jobs: state.jobs.posts,
    error: state.jobs.error,
    dataLoaded: state.jobs.isLoaded,
    strings: state.textstrings.strings,
    stringsLoaded: state.textstrings.isLoaded,
  }),
  dispatch => ({ dispatch })
)(JobsList)

export default WithData
