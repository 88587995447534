document.addEventListener('DOMContentLoaded', function() {
  var flipBoxes = document.querySelectorAll('.dhsv_vc_flip_box')

  flipBoxes.forEach(function(flipBox) {
    var contentCard = flipBox.querySelector('.content_card')

    flipBox.addEventListener('mouseenter', function() {
      if (window.innerWidth > 768) {
        // Füge die Klasse 'hovered' zur content_card hinzu
        window.requestAnimationFrame(function() {
          contentCard.classList.add('hovered')
        })
      }
    })

    flipBox.addEventListener('mouseleave', function() {
      if (window.innerWidth > 768) {
        // Entferne die Klasse 'hovered' von der content_card
        window.requestAnimationFrame(function() {
          contentCard.classList.remove('hovered')
        })
      }
    })

    // Klick-Verhalten für Mobilgeräte
    flipBox.addEventListener('click', function() {
      if (window.innerWidth <= 768) {
        // Toggle die Klasse 'clicked' auf der content_card
        window.requestAnimationFrame(function() {
          contentCard.classList.toggle('clicked')
        })
      }
    })
  })
})
