import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_onlineform()
})

function dhsv_vc_onlineform() {
  $('.dhsv_vc_onlineform').each(function() {
    const formContainer = $(this)
    const formId = formContainer.data('form-id')
    $(document).on('gform_page_loaded', function(event, form_id, current_page) {
      if (form_id == formId) {
        const nextPage = parseInt(current_page) + 1
        const prevPage = parseInt(current_page) - 1
        formContainer.find('[data-step="' + prevPage + '"]').addClass('done')
        formContainer
          .find('[data-step="' + nextPage + '"]')
          .removeClass('active')
        formContainer
          .find('[data-step="' + parseInt(current_page) + '"]')
          .removeClass('done')
          .addClass('active')
      }
    })
    $(document).on('gform_confirmation_loaded', function(event, form_id) {
      if (form_id == formId) {
        formContainer
          .addClass('confirmation-loaded')
          .find('.js-last-step')
          .addClass('active')
          .prev()
          .addClass('done')
      }
    })
  })
}
