import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../../scripts/components/Spinner'
import LoadDomHandler from './DomHandler'

class SocialItem extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    LoadDomHandler()
  }

  render() {
    const { item } = this.props

    if (!item) {
      return <Spinner />
    }

    const photoStyle = item.image
      ? {
          backgroundImage: 'url(' + item.image + ')',
        }
      : {}

    return (
      <div className="col-lg-3 col-sm-6 col-xs-12">
        <a href={item.url} className="socials__item" target="_blank">
          <div className="socials__item-image bg-cover" style={photoStyle}>
            {item.type === 'video' ? <span className="play-btn" /> : null}
            <span className="socials__item-network">
              <div
                className="social-icon__wrapper"
                dangerouslySetInnerHTML={{ __html: item.network }}
              />
            </span>
          </div>
          <p className="date index">{item.date}</p>
          <p className="socials__item-content" data-text-rows="3">
            {item.content}
          </p>
        </a>
      </div>
    )
  }
}

SocialItem.propTypes = {
  item: PropTypes.object,
}

export default SocialItem
