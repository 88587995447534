import { connect } from 'react-redux'
import SearchList from './SearchList'

const WithData = connect(
  state => ({
    posts: state.search.posts,
    found_posts: state.search.found_posts,
    error: state.news.error,
    dataLoaded: state.search.isLoaded,
    strings: state.textstrings.strings,
    stringsLoaded: state.textstrings.isLoaded,
  }),
  dispatch => ({ dispatch })
)(SearchList)

export default WithData
