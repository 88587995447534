import React from 'react'
import PropTypes from 'prop-types'
import SocialsList from './socials-data'

const Socials = ({ id, show }) => {
  return <SocialsList id={id} show={show} />
}

Socials.propTypes = {
  id: PropTypes.string,
  show: PropTypes.string,
}

export default Socials
