import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_testimonials_slider()
})

function dhsv_vc_testimonials_slider() {
  $('.dhsv_vc_testimonials_slider').each(function(index, el) {
    const $slider = $(el)

    $slider.slick({
      dots: true,
      arrows: true,
      infinite: true,
      speed: 300,
      autoplay: false,
      // autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      //adaptiveHeight: true,
    })
  })
}
