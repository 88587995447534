// $(document).ready(function() {
//   dhsv_vc_aktuelles()
// })
//
// function dhsv_vc_aktuelles() {
// }

import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_posts_slider()
})

function dhsv_vc_posts_slider() {
  $('.dhsv_vc_aktuelles').each(function(index, el) {
    let $slider = $('.posts-slider', $(el))

    $slider.slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 300,
      autoplay: false,
      // autoplaySpeed: 3000,
      slidesToShow: 2,
      slidesToScroll: 2,
      pauseOnHover: false,
      pauseOnFocus: false,
      adaptiveHeight: false,

      responsive: [
        // {
        //   breakpoint: 1200,
        //   settings: {
        //     slidesToShow: 3,
        //     slidesToScroll: 3,
        //     infinite: true,
        //   },
        // },
        // {
        //   breakpoint: 992,
        //   settings: {
        //     slidesToShow: 2,
        //     slidesToScroll: 2,
        //     infinite: true,
        //   },
        // },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    })
  })
}

//when the slick slide initializes we want to set all of our slides to the same height
$('.dhsv_vc_posts_slider').on('setPosition', function() {
  resizeSlickSlider()
})

$('.dhsv_vc_posts_slider').on('beforeChange', function() {
  resizeSlickSlider()
})

//we need to maintain a set height when a resize event occurs.
//Some events will through a resize trigger: $(window).trigger('resize');
$(window).on('resize', function() {
  resizeSlickSlider()
})

//since multiple events can trigger a slider adjustment, we will control that adjustment here
function resizeSlickSlider() {
  const $slickSlider = $('.dhsv_vc_posts_slider')
  const slickTrack = $slickSlider.find('.slick-track')
  const slickTrackHeight = $(slickTrack).height()

  $slickSlider.find('.slick-arrow').css('top', slickTrackHeight / 2 + 'px')
}
