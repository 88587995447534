import $ from 'jquery'
import * as ICS from 'ics-js'

function save(fileURL) {
  let fileName = 'Event.ics'
  if (!window.ActiveXObject) {
    var save = document.createElement('a')
    save.href = fileURL
    save.target = '_blank'
    save.download = fileName || 'unknown'

    var evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    })
    save.dispatchEvent(evt)
    ;(window.URL || window.webkitURL).revokeObjectURL(save.href)
  } else if (!!window.ActiveXObject && document.execCommand) {
    // for IE < 11
    var _window = window.open(fileURL, '_blank')
    _window.document.close()
    _window.document.execCommand('SaveAs', true, fileName || fileURL)
    _window.close()
  }
}

$(document).on('click', '.event__btn--term', function(e) {
  e.preventDefault()

  const cal = new ICS.VCALENDAR()

  cal.addProp('VERSION', 2) // Number(2) is converted to '2.0'
  cal.addProp('PRODID', '-//Fliedner-Fachhhochschule//EVENT//DE')

  var eventCard = $(this).closest('.event__card')
  var date = eventCard.find('.event__date').attr('datetime')
    ? eventCard.find('.event__date').attr('datetime')
    : new Date()
  var time = eventCard.find('.event__time').attr('datetime')
    ? eventCard.find('.event__time').attr('datetime')
    : '00:00'
  var email = eventCard.find('.event-cont__email').attr('href')
    ? eventCard.find('.event-cont__email').attr('href')
    : ''
  var location = eventCard.find('.event__location').text()
    ? eventCard.find('.event__location').text()
    : ''
  var title = eventCard.find('.event__title').text()
  var body = eventCard.find('.event__body').text()
    ? eventCard.find('.event__body').text()
    : ''

  const event = new ICS.VEVENT()
  event.addProp('UID')
  event.addProp('CREATED', new Date())
  event.addProp('DTSTAMP', new Date(date + ' ' + time))
  event.addProp('DTSTART', new Date(date + ' ' + time))
  event.addProp('ATTENDEE', null, {
    CN: 'Fliedner Fachhhochschule',
    RSVP: email,
  })
  event.addProp('LOCATION', location)
  event.addProp('SUMMARY', title)
  event.addProp('DESCRIPTION', body)

  cal.addComponent(event)

  var dlurl = 'data:text/calendar;utf8,' + cal.toString(event)
  save(dlurl)
})

// function getUrlParameter(parameter) {
//   var results = new RegExp('[/?&]' + parameter + '=([^&#]*)').exec(
//     window.location.href
//   )
//   return results[1] || 0
// }

$(document).ready(function() {
  $(document).on('click', '.event__link', function(e) {
    e.preventDefault()
    e.stopPropagation()
    if ($(this).attr('target') === '_blank') {
      window.open(this.href, '_blank')
    } else {
      window.location = this.href
    }
  })
})

$(document).on('show.bs.collapse', function(e) {
  $(e.target)
    .closest('.event__card')
    .addClass('event__card--active')
})

$(document).on('hide.bs.collapse', function(e) {
  $(e.target)
    .closest('.event__card')
    .removeClass('event__card--active')
})

// function showContent() {
//   if (getUrlParameter('event_id').length) {
//     const event_id = getUrlParameter('event_id')
//     $(document)
//       .find('#collapse-' + event_id)
//       .collapse('show')
//   }
// }
//
// $(window).on('load', function() {
//   showContent()
// })
