import React from 'react'
import './style.scss'

const Spinner = () => {
  return (
    <div className="spinner">
      <span className="icon ion-load-c" />
    </div>
  )
}

export default Spinner
